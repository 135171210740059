<template>
  <iframe
    :src="GenerateURL()"
    frameborder="0"
    style="
      overflow: hidden;
      overflow-x: hidden;
      overflow-y: hidden;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
    "
    height="150%"
    width="150%"
  />
</template>

<script>
export default {
  components: {},
  methods: {
    GenerateURL() {
      return `${this.$baseURL}/godmode.html`;
    },
  },
};
</script>
